import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import React, {useState} from "react";
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import cn from "classnames";
import lodash from 'lodash'
import {
    commonColors,
    dropdownDefaultStyles,
    inputRootError,
    quoteInputTextStyles
} from "../../../assets/jss/commonStyles";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    baseSelect: {
        boxSizing: 'border-box',
        padding: '0px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 12,
        border: '1px solid #ced4da',
        borderRadius: 24,
        height: 45,
        ...dropdownDefaultStyles
    },
    baseRoot: {
        width: '100%',
        height: '45px',
        backgroundColor: 'white',
        '& $baseSelect:focus': {
            background: 'none',
            borderRadius: '24px',
        },
        ...quoteInputTextStyles,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important'
        }
    },
    menu: {
        maxHeight: 300,
        // overflowY: 'scroll'
    },
    baseSelectError: {
        ...inputRootError
    }
});

const getOptionValue = (value) => {
    if (lodash.isObject(value)) {
        return value.id;
    }

    return value;
}

const getOptionRenderName = (value) => {
    if (lodash.isObject(value)) {
        if (value.name === 'Managed freight solutions') return 'Managed fre...';
        return value.name;
    }

    return value;
}

const getRenderValue = (options, selectedValue) => {
    if (lodash.isArray(options)) {
        return getOptionRenderName(
            options.find(option => option.id == selectedValue)
        );
    }

    return selectedValue;
}

const DropdownSelect = ({
                            input,
                            options,
                            disableUnderline = true,
                            classes = {},
                            requiredClass,
                            placeholder='Selected...',
                            meta: {error, touched},
                            ...custom
                        }) => {
    const [open, setOpen] = useState(false);
    const IconComponent = open ? ArrowDropUp : ArrowDropDown;

    return (
        <><Select
            {...custom}
            {...input}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            fullWidth
            value={getOptionValue(input.value)}
            onChange={(e) => input.onChange(e)}
            input={<Input/>}
            IconComponent={IconComponent}
            disableUnderline={true}
            MenuProps={{
                classes: {
                    paper: cn(classes.menu, classes.paper)
                },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                getContentAnchorEl: null,
            }}
            classes={{
                root: cn(classes.baseRoot, classes.root),
                select: cn(classes.baseSelect, classes.select,
                    {
                        [classes.baseSelectError]: requiredClass && error && touched
                    },
                    {
                        [classes.select_error]: requiredClass && error && touched
                    }
                )
            }}
            displayEmpty
            renderValue={(selected) => {
                if (!selected) {
                    return <span style={{
                        fontSize: 16,
                        color: '#808080',
                        fontWeight: 400,
                        fontFamily: 'Lato, sans-serif'
                    }}>{placeholder}</span>;
                }

                return getRenderValue(options, selected);
            }}
        >
            {options.map(option => (
                <MenuItem
                    key={option.id}
                    value={option.id}
                >
                    {option.name}
                </MenuItem>
            ))}
        </Select>
    {error && touched && (<span className={cn('invalidTxt', classes.input_error)}>
                    {error}
        </span>)}
        </>)
}

export default withStyles(styles)(DropdownSelect);
