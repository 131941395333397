export const templates = {
  defaultTemplate: 'Default',
  full: 'Full',
  singleRateV1: 'SingleRate_v1',
  singleRateV2: 'SingleRate_v2',
  singleRateV3: 'SingleRate_v3',

  routes: {
    '1': '/customer/quote-results/res',
    '2': '/customer/quote-results/c-1',
    '3': '/customer/quote-results/c-110',
    '4': '/customer/quote-results/c-10',
    '5': '/customer/quote-results/c-10',
    '6': '/customer/quote-results/c-10'
  },

  isSingleRate(template) {
    return this.isSingleRateV1(template) || this.isSingleRateV2(template);
  },

  isSingleRateV1(template) {
    return [this.defaultTemplate, this.singleRateV1].includes(template);
  },

  isSingleRateV2(template) {
    return this.singleRateV2 === template;
  },

  isSingleRateV3(template) {
    return this.singleRateV3 === template;
  },

  isFull(template) {
    return this.full === template;
  },

  handleTemplateName(template){
    let templateName = this.full;
    if (this.isSingleRateV1(template)) {
      templateName = this.singleRateV1;
    } else if (this.isSingleRateV2(template)) {
      templateName = this.singleRateV2;
    }else if(this.isSingleRateV3(template)){
      templateName = this.singleRateV3;
    }

    return templateName;
  },

  handleTemplateRoute(template, history) {
    history.push(this.routes[template.toString()])

    // if (this.isSingleRateV1(template)) {
    //   history.push('/customer/single-rate');
    // } else if (this.isSingleRateV2(template)) {
    //   history.push('/customer/single-rate-v2');
    // } else {
    //   history.push('/customer/quote-results');
    // }
  }
}
