import React, {Component} from "react";
import {Grid, withStyles} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from '../../../forms/AuthV2/styles';

class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {classes, switchForm, switchTo, text, buttonText, show} = this.props;

        return (
            <DialogTitle className={classes.titleContainer}>
                <Grid container justify="center" alignItems="center" className={classes.header}>
                    <Grid item>
                        <DialogContentText className={classes.headerText}>
                            { show ? text : ''}
                        </DialogContentText>
                    </Grid>
                    { show &&
                        <Grid item>
                            <Button onClick={() => switchForm(switchTo)} className={classes.headerButton} type={'button'} variant="contained">
                                {buttonText}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </DialogTitle>
        );
    }
}

export default withStyles(styles)(Header);
