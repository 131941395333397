import React from "react";
import { connect } from "react-redux";
import ResetPasswordForm from '../../forms/resetPasswordForm'
import Loader from '../../atoms/Loader'
import authService from "../../../services/authService";
import * as actions from "../../../store/login/actions";


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
        this.handleResetPassword = this.handleResetPassword.bind(this);

    }

    componentWillMount() {
        Window['history'] = this.props.history
        const hash = window.location.pathname.split('/')[2];

        if (hash !== 'updateTempPassword') {
            this.props.hashInfo(hash, this.props.currentLanguage, this.props.dictionaries)
        } else {
            this.props.setResetPasswordEmail();
        }
    }
    // handle reset password
    handleResetPassword(values) {
        this.props.resetPassword(
            {
                customerEmailId: values.email,
                activationCode: window.location.pathname.split('/')[2],
                password: values.password,
                tmpPasswordStatus: sessionStorage.getItem('tmpPasswordStatus')
            },
            this.props.currentLanguage,
            this.props.dictionaries
        )
        this.setState({ loading: true })
    }

    render() {
        return (
            <div className="mainSection resetpassword">
                <div className="loginwrapper">
                    <div className="innWrapper">
                        <div className="loginTitle">
                            <h2>My Freight Center</h2>
                        </div>
                        <div className="loginOverlay">
                            <div className="loginOverlayinn relative">
                                <div className="leftColumn">
                                    <div className="wraper">
                                        <ul>
                                            <li className="active">Password Reset<i class="zmdi zmdi-long-arrow-right"/></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="rightColumn">
                                    <div className="login-page">
                                        <div className="formSection">
                                            <ResetPasswordForm onSubmit={this.handleResetPassword} />
                                        </div>
                                        <div className="textSection"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.loading && <Loader />}
            </div>
        );
    }
}
// map state to props
const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading
});
// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
    resetPassword: (inputDTO, currentLanguage, dictionaries) => {
        authService.resetPassword(dispatch, currentLanguage, dictionaries, inputDTO)
    },
    hashInfo: (hash, currentLanguage, dictionaries) => {
        authService.hashInfo(dispatch, currentLanguage, dictionaries, hash)
    },
    setResetPasswordEmail: () => {
        dispatch(actions.setResetPasswordEmail(sessionStorage.getItem('resetPasswordEmail')))
    },
});
// connect to store
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

